.container {
  background-color: var(--color-bg-shade);
  padding-top: var(--spacing-top);
  padding-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: 1089px; /* 10/12 grid */
}

.header {
  margin-bottom: 56px;
  margin-inline: auto;
  max-width: 500px;
  text-align: center;

  @media (width >= 1000px) {
    margin-bottom: 64px;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

/* SHARED ITEMS */
.question-button {
  --cosmos-button-min-width: 100%;
  --cosmos-button-border-radius: 12px; /* magic number */
  --focus-offset: var(--cosmos-button-border-width, 1px);
  --focus-radius: var(--cosmos-button-border-radius);

  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  box-shadow: var(--elevation-1-above);
  border-width: var(--cosmos-button-border-width, 1px);
  border-style: solid;
  border-color: var(--cosmos-button-border-color, transparent);
  border-radius: var(--cosmos-button-border-radius, 9999px);
  background-color: var(--color-surface-solid-light-lighter);
  padding-inline: 16px;
  padding-block: 14px; /* -2 for border width */
  width: 100%;
  max-width: 100%;
  text-align: left;
  text-decoration: none;

  &[data-variant="accordion"] {
    margin-inline: auto;
    background-color: rgba(255, 255, 255, 1);
    padding-inline: 8px;
    padding-block: 6px;
    width: auto;
  }

  &[data-variant="tab"] {
    margin-inline-end: 32px;
    background-color: color-mix(
      in srgb,
      var(--color-surface-solid-light-lighter),
      transparent 50%
    );
    width: 100%;

    &[aria-selected="true"] {
      box-shadow: var(--elevation-2-above);
      background-color: var(--color-surface-solid-light-lighter);
    }
  }

  &:hover {
    box-shadow: var(--elevation-2-above);
    background-color: var(--color-surface-solid-light-lighter);
  }
}

/* TODO: Make some kine of universal component, Copied from Cosmos */
.question-button:focus::before {
  position: absolute;
  top: calc((4px + var(--focus-offset, 0px)) * -1);
  right: calc((4px + var(--focus-offset, 0px)) * -1);
  bottom: calc((4px + var(--focus-offset, 0px)) * -1);
  left: calc((4px + var(--focus-offset, 0px)) * -1);
  box-shadow:
    inset 0 0 0 2px #1b6aee,
    0 0 0 2px #ffffff;
  border-radius: var(--focus-radius);
  content: "";
}

/* TODO: Make some kine of universal component, Copied from Cosmos */
.question-button:focus,
.cosmos-button:focus:not(:focus-visible) {
  outline: none;
}

/* TODO: Make some kine of universal component, Copied from Cosmos */
.question-button:focus:not(:focus-visible)::before {
  content: none;
}

/* TODO: Make some kine of universal component, Copied from Cosmos */
.button-text {
  --cosmos-text-color: currentColor;
  --cosmos-text-font-family: var(--cosmos-button-font-family);
  --cosmos-text-font-size: var(--cosmos-button-font-size);
  --cosmos-text-font-style: var(--cosmos-button-font-style);
  --cosmos-text-font-weight: var(--cosmos-button-font-weight);
  --cosmos-text-letter-spacing: var(--cosmos-button-letter-spacing);
  --cosmos-text-text-transform: var(--cosmos-button-text-transform);
  --cosmos-text-line-height: 1.5;

  align-self: center;
  margin-inline-start: calc(16rem / var(--cosmos-base-font-size, 16));

  .question-button[data-variant="accordion"] & {
    margin-inline-start: calc(8rem / var(--cosmos-base-font-size, 16));
  }
}

.button-icon {
  flex-shrink: 0; /* stop long text squeezing icon */
  transition: transform 250ms ease-in-out;
  margin-inline-start: 8px;
  margin-block: auto; /* vertical centre align */
  color: var(--color-text-subtle);

  .question-button[aria-expanded="true"] & {
    transform: rotate(-180deg);
  }
}

.question-button[data-variant="accordion"][aria-expanded="true"] {
  box-shadow: var(--elevation-1-above);
  background-color: rgba(255, 255, 255, 1);
}

html[dir="rtl"] .button[aria-expanded="true"] .button-icon {
  transform: rotate(180deg);
}

.graphic {
  flex-shrink: 0;
  width: 40px;
  height: 40px;

  .question-button[data-variant="accordion"] & {
    width: 32px;
    height: 32px;
  }

  .question-button[data-variant="tab"] &,
  .question-button[data-variant="accordion"] & {
    opacity: 0.5;
  }

  .question-button[data-variant="tab"]:hover &,
  .question-button[data-variant="tab"][aria-selected="true"] &,
  .question-button[data-variant="accordion"]:hover &,
  .question-button[data-variant="accordion"][aria-expanded="true"] & {
    opacity: 1;
  }
}

/* END - SHARED ITEMS */

/* TABS */
.tabs {
  --_gap: 64px;

  display: flex;
  flex-direction: row;
  gap: var(--_gap);
}

.tab-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: calc(50% - (var(--_gap) / 2));
}

.tab-panels {
  width: calc(50% - (var(--_gap) / 2));
}

.tab-panel {
  --prose-font-size: var(--fluid-type-medium);
  --prose-line-height: var(--type-medium-line-height);
}

/* ACCORDION */
.accordion {
  margin-inline: auto;
  width: 100%;
  max-width: 75ch;
}

/* space between accordion items */
.accordion-item + * {
  margin-top: 8px;
}

/* 
   This is wrapper we use to toggle the `hidden`, it's needed because of top margin quirks.
   We have a couple inner divs to control `height: 0` -> `height: auto` effects
*/
.accordion-answer {
  --prose-font-size: var(--fluid-type-medium);
  --prose-line-height: var(--type-medium-line-height);

  display: grid; /* hack - set to `block` allows for collapsing margins compared to if this was `grid` */
  grid-template-rows: 1fr; /* 0 height */
  transition: grid-template-rows 500ms;
  text-align: center;

  &[hidden] {
    grid-template-rows: 0fr;
  }
}

.accordion-answer-inner {
  overflow: hidden;
}

.accordion-answer-inner-padding {
  padding-top: 12px;
  padding-bottom: calc(24px - 8px);
}

.question {
  grid-column-start: 1;
  grid-column-end: 1;
}

.answer {
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 2;
  grid-column-end: -1;
}

.prose-cta {
  margin-top: var(--spacing-loose);
}
