.container {
  --navigation-dots-offset: 12%;
  --card-stack-card-spacing-factor: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: var(--spacing-sides);
  padding-block: 80px;
  overflow: hidden;

  @media (width >= 650px) {
    --navigation-dots-offset: 10%;
  }

  @media (width >= 768px) {
    padding-block: 100px;
  }

  @media (width >= 1000px) {
    padding-block: 120px;
  }
}

.container--silver-bg {
  background: var(--color-bg-shade);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: repeat(3, auto);
  }
}

.title {
  --kicker-font-size: var(--font-size-18px);

  margin-bottom: 40px;
  max-width: 590px;

  /* if this font-size isn't set the grid positioning is stange */
  font-size: var(--kicker-font-size);
  text-align: center;

  @media (width >= 1000px) {
    grid-column: 1 / -1;
    margin-inline: auto;
    margin-bottom: 40px;
  }

  .container:not(.container--silver-bg) & {
    --kicker-color: var(--page-theme-contrast-color, unset);
  }
}

.video-wrapper {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (width >= 1000px) {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
  }
}

.video-wrapper--gradient-white::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to top,
    var(--color-surface-solid-light-lighter) 0,
    rgba(255, 255, 255, 0) 20px
  );
  background-repeat: no-repeat;
  background-blend-mode: normal;
  width: 100%;
  height: 100%;
  content: "";

  @media (width >= 1000px) {
    background: linear-gradient(
        to top,
        var(--color-surface-solid-light-lighter) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to bottom,
        var(--color-surface-solid-light-lighter) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to left,
        var(--color-surface-solid-light-lighter) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to right,
        var(--color-surface-solid-light-lighter) 0%,
        rgba(255, 255, 255, 0) 100px
      );
  }
}

.video-wrapper--gradient-grey::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to top,
    var(--color-bg-shade) 0,
    rgba(255, 255, 255, 0) 20px
  );
  background-repeat: no-repeat;
  background-blend-mode: normal;
  width: 100%;
  height: 100%;
  content: "";

  @media (width >= 1000px) {
    background: linear-gradient(
        to top,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to bottom,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to left,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      ),
      linear-gradient(
        to right,
        var(--color-bg-shade) 0,
        rgba(255, 255, 255, 0) 100px
      );
  }
}

.video {
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  max-height: calc((100vw - var(--spacing-sides)) / 1.6);

  @media (width >= 800px) {
    max-height: 540px;
  }
}

.facts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row: 2 / -1;
    grid-column: 1 / -1;
    margin-top: 50%;
    grid-column-gap: 24px;
    position: relative;
    align-items: initial;
  }
}

@media (width >= 1000px) {
  .facts-wrapper--no-asset {
    margin-top: 0;
  }
}

.quote {
  position: relative;
  z-index: 1;
  margin-top: calc(16px + 39px + 40px); /* icon = 39px */
  margin-bottom: 40px;
  max-width: 450px;
  font-weight: bold;
  font-size: var(--font-size-24px);
  line-height: 1.33;
  text-align: center;

  @media (width >= 650px) {
    max-width: 550px;
    font-size: var(--font-size-48px);
    line-height: 1.14;
  }

  @media (width >= 1000px) {
    grid-column: 1 / 7;
    margin-top: 25%;
    margin-bottom: 40px;
    max-width: 550px;
    text-align: initial;
  }

  @media (width >= 1200px) {
    max-width: 700px;
    font-size: var(--font-size-56px);
    line-height: 1.14;
  }

  &[data-width="full"] {
    max-width: 100%;
  }

  &[data-align="end"] {
    grid-column: 7 / -1;
  }
}

/* `data-align=full` gets added where there is no card stack  */
.quote[data-width="full"][data-align="start"],
.quote[data-width="full"][data-align="end"] {
  grid-column: 1 / -1;
  text-align: center;
}

@media (width >= 1000px) {
  .quote[data-width="full"] {
    margin-top: var(--spacing-top);
  }
}

.quote::after {
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("./quote-mark.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 44px;
  height: 32px;
  content: "";

  @media (width >= 650px) {
    width: 55px;
    height: 39px;
  }

  @media (width >= 1000px) {
    top: -65px;
    transform: none;
    inset-inline-start: 0; /* RTL */
  }

  @media (width >= 1200px) {
    width: 62px;
    height: 44px;
  }
}

.quote[data-width="full"]::after {
  left: 50%;
  transform: translateX(-50%);
}

.container:not(.container--silver-bg) .quote {
  color: var(--page-theme-contrast-color, var(--color-text));
}

.cards-stack-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;

  @media (width >= 1000px) {
    grid-row: 1 / 3;
    grid-column: 7 / -1;
  }

  @media (width >= 1440px) {
    justify-content: flex-end;
  }

  &[data-align="start"] {
    grid-column: 1 / 7;
  }
}

@media (width >= 1440px) {
  .cards-stack-wrapper[data-align="start"] {
    display: flex;
    justify-content: flex-start;
  }
}

.card-stack-inner-wrapper {
  width: 272px;

  @media (width >= 650px) {
    width: 330px;
  }

  @media (width >= 1200px) {
    width: 384px;
  }
}

.read-more-link-wrapper {
  display: flex;
  align-items: flex-end;
  margin-top: 40px;

  @media (width >= 1000px) {
    grid-column: 1 / 7;
    margin-top: initial;
  }
}

/* CSS Order: place before `[data-active="true"]` */
.read-more-link-wrapper[data-align="end"] {
  grid-column: 7 / -1;
}

.read-more-link-wrapper[data-width="full"].read-more-link-wrapper[data-align="start"],
.read-more-link-wrapper[data-width="full"].read-more-link-wrapper[data-align="end"] {
  grid-column: 1 / -1;
  justify-content: center;
  margin-top: initial;
}

.read-more-link {
  --cosmos-button-color: var(--color-primary);
  --cosmos-button-color-hover: var(--color-primary-darker);
  --cosmos-button-border-color-hover: color-mix(
    in srgb,
    var(--color-primary-darker),
    transparent 80%
  );
}

.read-more-link-icon {
  transition: transform 250ms ease-in-out;

  .read-more-link[aria-expanded="true"] & {
    transform: rotate(-180deg);

    html[dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
}

.hidden-content {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 500ms;
  width: 100%;
  height: auto;

  @media (width >= 1000px) {
    grid-row-end: -1;
    grid-column: 1/-1;
    max-width: var(--max-width-base); /* this might not be needed */
  }

  &[hidden] {
    grid-template-rows: 0fr;
  }
}

.hidden-content-inner {
  overflow: hidden;
}

.hidden-content-inner-margin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-top);
}

.panel-column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  max-width: 450px;

  @media (width >= 1000px) {
    max-width: none;
  }
}

.panel-column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media (width >= 1000px) {
    flex-direction: row;
    align-items: initial;
    gap: 24px;
    box-sizing: content-box;
    margin-bottom: 80px;
  }
}

.kicker-wrapper {
  --kicker-font-size: var(--font-size-18px);

  margin-inline: 20px;
  margin-bottom: 8px;
}

.panel-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.panel-column-image {
  mix-blend-mode: multiply;
  margin-bottom: 20px;
  aspect-ratio: 1;
  width: 100%;
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
}

.panel-column-card {
  box-shadow: 0 16px 32px 0 rgba(0, 30, 60, 0.12);
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  padding: 24px;
  max-width: 350px;

  @media (width >= 1000px) {
    display: flex;
    flex-direction: column;
    padding: 32px;
    height: 100%;
  }
}

.panel-column-headline {
  margin-bottom: 25px;
  color: var(--color-primary);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-24px);
  line-height: 1.33;
  font-family: var(--font-family-heading);

  @media (width >= 1000px) {
    font-size: var(--font-size-32px);
    line-height: 1;
  }
}

.panel-column-text {
  font-size: var(--font-size-14px);
  line-height: 1.5;

  @media (width >= 1000px) {
    font-size: var(--font-size-16px);
    line-height: 1.4;
  }
}

.text-intro-wrapper {
  margin-bottom: 40px;
  max-width: 588px;

  @media (width >= 1000px) {
    margin-bottom: 60px;
  }
}

.text-intro {
  margin-bottom: 15px;
  font-size: var(--font-size-16px);
  line-height: 1.5;
  text-align: center;

  @media (width >= 1000px) {
    margin-bottom: 25px;
    font-size: var(--font-size-20px);
    line-height: 1.4;
  }
}

.text-intro--bold {
  font-family: var(--ff-bull);
}

.text-intro--italic {
  font-style: italic;
}

.text-intro-hyperlink,
.phase-side-image-column-hyperlink {
  color: var(--color-primary);
  font-weight: 500;
  text-decoration: none;
}

.phase-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media (width >= 1000px) {
    margin-bottom: 60px;
  }
}

.phase-image {
  margin-block: 40px;
  border-radius: var(--radius-large);
  width: 100%;
  max-width: 680px;
}

.phase-side-image-column-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;

  @media (width >= 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: initial;
    margin-bottom: 40px;
  }

  @media (width >= 1000px) {
    margin-bottom: 60px;
  }
}

.phase-side-image-column-item {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
  margin-bottom: 40px;
  max-width: 450px;
  text-align: center;

  @media (width >= 768px) {
    justify-content: initial;
    align-items: flex-start;
    padding-inline: 50px;
    padding-block: 24px;
    width: calc(50% - 12px);
    max-width: none;
    text-align: initial;
  }

  @media (width >= 1000px) {
    flex-direction: row;
    justify-content: center;
    padding: 24px;
  }
}

.phase-side-image-column-image {
  mix-blend-mode: multiply;
  margin-bottom: 20px;
  width: 80%;
  max-width: 300px;

  @media (width >= 768px) {
    width: 170px;
  }

  @media (width >= 1000px) {
    width: 140px;
  }
}

.phase-side-image-column-text-wrapper {
  display: flex;
  flex-direction: column;

  @media (width >= 1000px) {
    padding-left: 24px;
  }
}

.phase-side-image-column-headline {
  margin-bottom: 25px;
  color: var(--color-primary);
  font-weight: bold;
  font-size: var(--font-size-24px);
  line-height: 1.33;

  @media (width >= 1200px) {
    font-size: var(--font-size-32px);
    line-height: 1;
  }
}

.phase-side-image-column-text {
  font-size: var(--font-size-16px);
  line-height: 1.5;

  @media (width >= 1200px) {
    font-size: var(--font-size-20px);
    line-height: 1.4;
  }
}
