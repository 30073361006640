.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xx-loose);
  margin-bottom: var(--spacing-xx-loose);

  @media (width >= 1000px) {
    align-items: flex-end;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: var(--box-information-highlight-desktop-width);

  @media (width >= 1000px) {
    align-items: flex-end;
  }
}

.title {
  margin-bottom: var(--spacing-normal);
}

.text {
  margin-bottom: var(--spacing-loose);
  padding-inline: var(--spacing-xx-loose);

  /* As due to HTML structure margin collapse does not work
     we disable the bottom margin if the text is the only element 
  */
  &:only-child {
    margin-bottom: initial;
  }

  @media (width >= 1000px) {
    padding-inline: initial;
    padding-left: var(--spacing-xx-loose);
  }
}

.cards-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  gap: var(--spacing-tight);
}

.versus-pill {
  --_pill-width: 30px;

  position: absolute;
  top: calc(30%);
  left: calc(50% - var(--_pill-width) / 2);
  z-index: 10000;
  border-radius: var(--radius-full);
  background-color: rgb(
    191,
    196,
    199
  ); /* non-transparent version of --color-surface-glass-dark-20 */
  padding: var(--spacing-xx-tight) var(--spacing-x-tight);
}

.fact {
  margin-bottom: var(--spacing-loose);
}

.disclaimer {
  align-self: center;
  padding-inline: var(--spacing-xx-loose);
  text-align: center;
  @media (width >= 1000px) {
    padding-inline: initial;
  }
}
