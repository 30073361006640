.container {
  margin-top: var(--spacing-bottom);
  margin-bottom: 200px;
  background-color: var(--page-theme-background-color, var(--color-bg-shade));

  /* Do we want this padding on mobile? */
  padding-inline: 20px;
  padding-block: 100px;

  @media (width >= 768px) {
    padding-inline: initial;
    padding-block: initial;
  }
}

.inner {
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 1024px) {
    align-items: flex-start;
  }
}

.heading-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (width >= 1024px) {
    align-items: flex-start;
    text-align: left;
  }
}

.kicker {
  --kicker-font-size: var(--font-size-18px);

  [data-has-custom-theme] & {
    --kicker-color: var(--page-theme-contrast-color, unset);
    --kicker-border-color: var(--theming-kicker-border-color);
  }
}

.heading {
  display: block;
  margin-block: 40px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-36px);
  line-height: calc(48 / 36);
  font-family: var(--font-family-heading);
}

.text {
  margin-bottom: 50px;
  max-width: 364px;
  color: var(--page-theme-contrast-color, var(--color-text));
  font-size: var(--font-size-16px);
  line-height: calc(22 / 16);
  text-align: center;

  @media (width >= 1024px) {
    text-align: initial;
  }
}

/* TODO: prose class */
.text > * + * {
  margin-top: 1em;
}

.animation-container {
  display: none;
  position: relative;
  margin-left: -180px;

  @media (width >= 768px) {
    margin-left: -220px;
  }

  @media (width >= 1024px) {
    margin-left: initial;
  }
}

/* TODO: `animation-container` is used twice in the template, can it be used once with grid? */
.animation-container[data-variant="desktop"] {
  display: none;

  @media (width >= 1024px) {
    display: block;
  }
}

.animation-container[data-variant="mobile"] {
  display: block;

  @media (width >= 1024px) {
    display: none;
  }
}

.animation {
  width: 140px;

  @media (width >= 768px) {
    width: 180px;
  }
}

.button-wrapper {
  margin-top: 50px;

  @media (width >= 1024px) {
    margin-top: initial;
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

[data-has-custom-theme] .button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

html[dir="rtl"] .button-icon {
  transform: scaleX(-1); /* Speech-bubble icon flipped for RTL */
}

.bubble-container {
  position: absolute;
  top: 8%;
  right: -120%;
  box-shadow: 0 9px 19px rgba(0, 15, 30, 0.12);
  border-radius: 32px 32px 32px 0;
  background: var(--color-surface-solid-light-lighter);
  padding: 28px;

  @media (width < 768px) {
    right: -140%;
    padding: 18px;
  }
}

.bubble-text {
  min-width: 190px;
  max-width: 190px;
}

.contact-list {
  margin-top: var(--spacing-xx-loose);
}

.table-title {
  margin-bottom: var(--spacing-normal);
}

.table {
  border-collapse: collapse;
  max-width: 384px;
  font-size: var(--font-size-14px);
  line-height: 1.5;

  & tr:nth-child(even) {
    background-color: #e9e9ea;
  }

  & td {
    padding: 10px 20px;

    &:last-child {
      text-align: right;
    }
  }

  & a {
    color: inherit;
    text-decoration: none;
  }
}
