.container {
  background: var(--color-surface-solid-light);
  /* With this we prevent a short horizontal scroll appearing on card swiping of the card stack */
  overflow-x: clip;

  @media (width >= 1000px) {
    justify-self: center;
    /* Keeping the ratio from the 1440px width figma design */
    padding-inline-start: min(509px, calc((509 / 1440) * 100vw));
    padding-inline-end: min(175px, calc((175 / 1440) * 100vw));
    width: 100%;
    max-width: 1440px; /* magic number */
  }
}
