.container {
  --_kicker-color: var(--kicker-color, unset);
  --_kicker-font-size: var(--kicker-font-size, 16px);
  --_kicker-line-height: var(--kicker-line-height, 1.25);
  --_kicker-font-weight: var(--kicker-font-weight, 700);
  --_kicker-border-image: var(--kicker-border-image, initial);
  --_kicker-border-color: var(--kicker-border-color, var(--color-primary));

  display: inline-block; /* `display: block` will mess with the underline, add `block` to a wrapper component */
  color: var(--_kicker-color);
  font-weight: var(--_kicker-font-weight);
  font-size: var(--_kicker-font-size);
  line-height: var(--_kicker-line-height);
  text-transform: uppercase;
}

.container--underlined,
.container--underlined-sugarfree {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  padding-bottom: 6px;
}

.container--underlined {
  border-bottom-color: var(--_kicker-border-color);

  /* There is an edge case where a border image is applied, noting it here for reference */
  border-image: var(--_kicker-border-image);
}

.container--underlined-sugarfree {
  border-bottom-color: var(--color-sugar-free);
}

.container--bare {
  /* empty */
}

.container--tag-sugarfree {
  background-color: var(--color-sugar-free);
  padding-inline: var(--spacing-normal);
  padding-block: var(--spacing-xx-tight);
  color: var(--color-text-light);
}
