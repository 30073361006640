.container {
  --teaser-basic-text-align: center;
  /* on tablet some components position buttons at the bottom */
  /* as we used padding instead of margin to have white spacing, they appear to close to the border */
  margin-top: var(--spacing-top);
  background-color: var(--page-theme-background-color, var(--color-bg-light));
  padding-inline: var(--spacing-sides);
  padding-top: var(--spacing-top);
  /* making sure there's white spacing below the component on tablet and mobile*/
  padding-bottom: var(--spacing-bottom);

  @media (width >= 1000px) {
    margin-top: initial;
    padding-bottom: initial;
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);
}

.content {
  display: flex;
  flex-direction: column;

  @media (width >= 1000px) {
    column-gap: 200px;
    flex-direction: row;
    align-items: center;
  }
}

.media-wrap {
  margin-block: var(--spacing-x-loose);

  @media (width >= 650px) {
    margin-block: var(--spacing-xx-loose);
  }

  @media (width >= 1000px) {
    flex: 1;
    margin-block: var(--spacing-xxxx-loose);
  }
}

.img {
  position: relative;
  margin-inline: auto;
}

.video {
  width: 100%;
}

.benefits {
  display: flex;
  row-gap: var(--spacing-x-loose);
  flex-direction: column;

  @media (width >= 650px) {
    row-gap: var(--spacing-xx-loose);
  }

  @media (width >= 1000px) {
    flex: 1;
    margin-block: var(--spacing-xxxx-loose);
  }
}

.point {
  --prose-font-size: var(--fluid-type-medium);
  --prose-line-height: var(--type-medium-line-height);

  display: flex;
  row-gap: var(--spacing-x-tight);
  flex-direction: column;
}
