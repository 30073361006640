/**
 * On mobile we need to position: stick the buttons
 * bottom sticking requires the buttons to be the last element
 */
.mobile-button-wrapper {
  position: relative;
  isolation: isolate;
}

/*
.top-pin {}
*/

.container {
  --_can-shadow: var(
    --scheme-can-shadow-rgb,
    var(--can-subtle-shadow-color-rgb)
  );

  display: flex;
  position: relative;
  padding-inline: var(--spacing-sides);
  width: 100%;
  overflow: hidden;

  @media (width >= 1000px) {
    height: 100dvh;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);

  @media (width >= 1000px) {
    display: grid;
  }
}

/**
 * On mobile these buttons stick to the bottom of the screen
 */
.sticky-button-wrapper {
  --button-wrapper-margin-top: 0;

  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: center;

  @media (width >= 1000px) {
    display: none;
  }
}

/**
 * 1. Absolute means this takes up 0 height, and doesn't add awkward spacing at the end.
 * 2. Using padding here for GSAP translation reasons 
 */
.sticky-button-wrapper-inner {
  position: absolute; /* 1 */
  bottom: 0;
  padding-bottom: 16px; /* 1 */
}

.button-wrapper {
  display: none;

  @media (width >= 1000px) {
    display: flex;
  }
}

.stage {
  display: grid;
  position: relative;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-row-start: 1;
  z-index: 2;
  width: 100%;

  @media (width >= 1000px) {
    grid-template-rows: initial;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-start: 1;
    grid-column-start: 1;
    align-items: center;
    justify-items: center;
    gap: var(--spacing-loose);
    height: 100dvh;
  }
}

.can-wrapper {
  position: relative;
  grid-row-start: 2;
  grid-column-start: 1;
  z-index: 4;

  @media (width >= 1000px) {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 100%; /* 100% so it can be translated */
  }
}

.slide1 {
  --button-wrapper-justify-content: center;

  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 1;
  padding-block: var(--spacing-xxx-loose);

  @media (width >= 1000px) {
    --button-wrapper-justify-content: start;

    grid-row-start: 1;
    grid-column-start: 2;
    padding-block: var(--spacing-xxx-loose);
  }
}

.slide2 {
  position: relative;
  grid-row-start: 3;
  grid-column-start: 1;
  z-index: 2;
  padding-top: var(--spacing-xxx-loose);
  padding-bottom: calc(var(--spacing-xxx-loose) + 48px + 16px);

  @media (width >= 1000px) {
    grid-row-start: 1;
    grid-column-start: 1;
    padding-block: var(--spacing-xxx-loose);
  }
}

.slide3 {
  --teaser-basic-text-align: start;
  --button-wrapper-justify-content: start;

  grid-row-start: 2;
  grid-column-start: 1;
  align-self: center;
  z-index: 3;
  padding-block: var(--spacing-xxx-loose);
  width: 50%;

  @media (width >= 1000px) {
    --teaser-basic-text-align: start;
    --button-wrapper-justify-content: start;

    grid-row-start: 1;
    grid-column-start: 1;
    width: 100%;
  }
}

.stage3-text {
  padding-top: 32px;
}

/* In future this wrapper will hold dietary marks */
.picture {
  display: flex;
  margin-inline: auto;
  width: fit-content;
}

/* The product/Can */
.img {
  display: block;
  transform-origin: top center;
  filter: drop-shadow(
    var(--can-subtle-shadow-values)
      rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
  );
  width: 247px;
  height: auto;

  @media (width >= 1000px) {
    width: 176px;
  }
}
