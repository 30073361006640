.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 20px 0 rgba(0, 15, 30, 0.08);
  border-radius: var(--radius-medium);
  background-color: var(--color-surface-solid-light-lighter);
  padding: var(--spacing-tight);
  width: 150px /* magic number */;

  @media (width >= 650px) {
    width: 160px; /* magic number */
  }

  &[data-appearance="dark"] {
    box-shadow: initial;
    background-color: var(--color-solid-light-lighter);
  }
}
.title {
  margin-bottom: var(--spacing-x-tight);
}

.image {
  margin-bottom: var(--spacing-x-tight);
  max-height: 80px; /* magic number */
  object-fit: scale-down;
}

.details {
  line-height: normal;
}

.quantity-number {
  font-weight: var(--cosmos-font-weight-bold);
}
