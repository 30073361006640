.container {
  padding: var(--spacing-sides);

  @media (width >= 1000px) {
    padding-block: 100px;
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.content {
  background-color: var(--color-surface-solid-dark-darker);
  padding: 20px 20px 30px;

  @media (width >= 650px) {
    display: grid;
    align-items: center;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    padding: 20px;
  }
}

.logo {
  display: block;
  margin: 0 auto 25px;
  width: 100%;
  max-width: 400px;

  @media (width >= 650px) {
    grid-column-start: 1;
    grid-column-end: 5;
    margin: 0;
  }
}

.text {
  color: var(--color-text-light);
  font-weight: 700;
  font-size: var(--font-size-20px);
  text-align: center;

  @media (width >= 650px) {
    grid-column-start: 5;
    grid-column-end: 13;
    font-size: var(--font-size-26px);
    text-align: left;
  }
}
