.container {
  --prose-font-size: var(--fluid-type-medium);
  --prose-line-height: var(--type-medium-line-height);

  padding-inline: var(--spacing-sides);

  [data-has-custom-theme] & {
    color: var(--page-theme-contrast-color, var(--color-text));
  }
}

.inner {
  margin-inline: auto;
  margin-block: var(--spacing-xxx-loose); /* assumes Margin collapsing */
  max-width: var(--max-width-base);

  @media (width >= 1000px) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.contained {
  @media (width >= 650px) {
    grid-column: 4 / -3;
  }
}

.full-bleed {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

.end-offset {
  @media (width >= 650px) {
    grid-column: 4 / -1;
  }
}
