.container {
  margin-top: var(--spacing-sides);
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);
}

.inner {
  display: flex;
  column-gap: var(--spacing-xx-loose);
  flex-direction: column-reverse;
  margin-inline: auto;
  width: 100%;
  max-width: 900px;

  @media (width >= 650px) {
    flex-direction: row-reverse;
    align-items: center;
  }

  @media (width >= 1000px) {
    column-gap: var(--spacing-xxx-loose);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 650px) {
    align-items: flex-start;
  }
}

.kicker {
  --kicker-color: var(--color-text-subtle);

  margin-bottom: var(--spacing-x-tight);
  text-align: center;

  @media (width >= 650px) {
    margin-inline: initial;
    text-align: start;
  }
}

.heading {
  text-align: center;

  @media (width >= 650px) {
    text-align: start;
  }
}

.button {
  margin-top: var(--spacing-x-loose);

  @media (width >= 650px) {
    margin-top: var(--spacing-loose);
  }
}

.form {
  margin-top: var(--spacing-x-loose);
  width: 100%;

  @media (width >= 650px) {
    margin-top: var(--spacing-loose);
  }
}

.input-wrap {
  --cosmos-input-border-radius: 20px; /* 20px magic number half height of input */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-tight);
}

.input {
  flex-grow: 1;
  width: auto;
}

.image {
  display: block;
  margin-inline: auto;
  margin-bottom: var(--spacing-x-loose);
  width: 176px;
  height: auto;

  @media (width >= 650px) {
    margin-inline: initial;
    margin-bottom: 0;
  }
}

/**
 * START COSMOS
 * Comos input has label, we visually hide it.
 */

/* `cosmos-form-field__info-container` has a 8px margin */
.container cosmos-input::part(base) {
  margin-top: -8px;
}

/* Hide the label, other help texts would need to also be hidden */
.container cosmos-input::part(label) {
  clip: rect(0 0 0 0);
  position: absolute;
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
}

/* END COSMOS */
