.container {
  position: relative;
  margin-top: var(--spacing-top);
  margin-bottom: calc(var(--spacing-bottom) / 2);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);

  @media (width >= 650px) {
    margin-bottom: var(--spacing-bottom);
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.header {
  display: flex;
  position: relative; /* for dietary marks */
  flex-direction: column;
  margin-bottom: var(--spacing-loose);

  @media (width >= 1000px) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 24px;
    margin-bottom: var(--spacing-xxx-loose);
  }
}

.kicker {
  --kicker-color: var(--color-text-dark-subtle);

  grid-column: 3/-1;
  order: 3;
  margin-bottom: var(--spacing-tight);
}

.title {
  grid-column: 3/-1;
  order: 4;
}

.image {
  position: relative; /* for .img */
  grid-row: 3;
  grid-column: 1/3;
  order: 2;
  margin-inline: auto;
  margin-bottom: var(--spacing-xx-loose);
  width: 100%;
}

.img {
  display: block;
  margin-inline: auto;
  max-width: 175px;

  @media (width >= 1000px) {
    position: absolute;
    margin-inline: auto;
    inset-inline: 0;
    max-width: 100%;
  }
}

.breadcrumb {
  grid-column: 3/-1;
  order: 1;
  margin-bottom: var(--spacing-x-loose);
  margin-inline: auto;

  @media (width >= 1000px) {
    margin-bottom: var(--spacing-xx-loose);
    margin-inline: initial;
  }
}

.content {
  margin-top: var(--type-medium-spacing-top);

  @media (width >= 1000px) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin-bottom: var(--spacing-xxx-loose);
  }
}

.prose {
  --cosmos-text-font-size: var(--fluid-type-medium);
  --cosmos-text-line-height: var(--type-medium-line-height);

  grid-column: 5/-2;
  font-size: var(--cosmos-text-font-size);
  line-height: var(--cosmos-text-line-height);
}

/* If dietary marks exist, add some padding in the title */
.title:has(~ .dietary-mark-high-sugar) {
  padding-inline-end: 35px;

  @media (width >= 650px) {
    padding-inline-end: 45px;
  }

  @media (width >= 1000px) {
    padding-inline-end: initial;
  }
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-end: 0px;
  --high-sugar-mark-inset-inline-end: 0px;
  --high-sugar-mark-width: 35px;

  @media (width >= 650px) {
    --high-sugar-mark-width: 45px;
  }

  @media (width >= 1000px) {
    --high-sugar-mark-width: 55px;
    --high-sugar-mark-inset-block-end: initial;
    --high-sugar-mark-inset-block-start: 0px;
  }
}
