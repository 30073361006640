/* 2024 Revamp Component */

.button-wrapper {
  --_justify-content: var(--button-wrapper-justify-content, start);
  --_margin-top: var(
    --button-wrapper-margin-top,
    var(--cosmos-spacing-xx-loose, 40px)
  );
  --_flex-direction: var(--button-wrapper-flex-direction, row);

  display: flex;
  column-gap: var(--cosmos-spacing-normal, 16px);
  row-gap: var(--cosmos-spacing-normal, 16px);
  flex-direction: var(--_flex-direction);
  flex-wrap: wrap;
  justify-content: var(--_justify-content);
  align-items: center;
  margin-top: var(--_margin-top);
}
