.container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  --teaser-basic-text-align: left;

  @media (width >= 1000px) {
    margin-top: var(--spacing-top);
    margin-bottom: var(--spacing-bottom);
    padding-inline: var(--spacing-sides);
    height: 98dvh;
    --teaser-basic-text-align: left;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 24px;

    @media (width >= 1000px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0%;

  @media (width >= 1000px) {
    column-gap: 200px;
    flex-direction: row;
    align-items: center;
  }
}

.left-wrap {
  --_scheme-background: var(
    --scheme-background,
    var(--color-surface-solid-dark)
  );

  display: flex;
  position: sticky;
  top: var(--spacing-top);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: var(--cosmos-backdrop-blur-medium);
  box-shadow: var(--cosmos-elevation-1-above);
  border-radius: var(--cosmos-radius-large);
  background-color: var(--_scheme-background);
  width: 100%;
  height: 98dvh;
  overflow: hidden;

  @media (width >= 650px) {
    margin-block: var(--spacing-xx-loose);
    width: 100%;
    height: 98dvh;
  }

  @media (width >= 1000px) {
    position: relative;
    margin-block: var(--spacing-xxxx-loose);
    width: 80%;
    height: 50dvh;
    overflow: visible;
  }
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: -25dvh;
  height: 100dvh;

  @media (width >= 650px) {
    margin: auto;
    height: 70dvh;
  }

  @media (width >= 1000px) {
    clip-path: rect(-10dvh 100% 60dvh 0px);
    margin-top: -10dvh;
    margin-bottom: -50%;
    height: 80dvh;
    overflow: hidden;
    object-fit: cover;
  }
}

.right-wrap {
  --teaser-basic-padding-inline: 0;

  display: flex;
  position: sticky;
  row-gap: var(--spacing-xxx-loose);
  flex-direction: column;
  z-index: 1;
  margin-top: -10vh;
  border-radius: var(--cosmos-radius-large);
  background-color: white;
  padding: var(--spacing-loose);

  @media (width >= 650px) {
    row-gap: var(--spacing-xx-loose);
    margin-top: -10dvh;
    width: 100%;
  }

  @media (width >= 1000px) {
    flex: 1;
    align-items: start;
    margin-block: var(--spacing-xxxx-loose);
  }
}

.point {
  --prose-font-size: var(--fluid-type-medium);
  --prose-line-height: var(--type-medium-line-height);

  display: flex;
  row-gap: var(--spacing-x-tight);
  flex-direction: column;
  opacity: 1;

  @media (width >= 1000px) {
    &:nth-child(n + 2) {
      transform: translateY(100px);
      opacity: 0;
    }
    &:nth-child(1) {
      opacity: 1;
    }
  }
}
