/**
 * 1. Set via JS, eg desktop=172px or mobile=144px
 * 2. less than one, eg 0.1, target width is 200px. desktop=200/172 mobile=168/144 
 * 3. the Embla carousel need to match the number of visible slides in the "rotation"
 * 4. the transform origin of the rotation ((200-172)/2)
 * 5. the scaled up Can overflows the embla container by this amount ((200-172) / 2 )
 */
.container {
  --_slide-width: calc(var(--product-rail-mobile-slide-width) * 1px); /* 1 */
  --_scale-factor: 0.1627906977; /* 2 */
  --_visible-slides-in-rotator: var(--product-rail-visible-slides, 7); /* 3 */
  --_desktop-rotation-origin: 2750px; /* 4 */
  --_mobile-rotation-origin: 2750px; /* 4 */
  --_desktop-offset-overflow: 14px; /* 5 */
  --_carousel-width: calc(var(--product-rail-carousel-width, 688) * 1px);
  --_background: var(--color-surface-solid-light-darker);

  display: flex;
  position: relative;
  isolation: isolate;
  overflow: hidden;

  /*
  @media (width >= 1000px) {
    --_slide-width: calc(var(--product-rail-desktop-slide-width) * 1px); /* 1
  }
  */

  &::before {
    position: absolute;
    top: 0;
    z-index: 1;
    transition: background-color 0.25s linear;
    inset-inline-start: 0;
    background-color: var(--_background);
    width: 100%;
    height: 100%;
    content: "";

    /*
    @media (width >= 1000px) {
      width: 50%;
    }
    */
  }
}

/**
 * `is-dragging` is set via Embla Plugin
 * 1. Stop accidently highlighting of images nad text when dragging Embla 
 */
.container:has(.embla__viewport.is-dragging) {
  user-select: none; /* 1 */
}

.inner {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 2;
  margin-inline: auto;
  padding-block: var(--spacing-xxxx-loose, 80px);
  width: 100%;
  max-width: var(--max-width-revamp-base);

  /*
  @media (width >= 1000px) {
    flex-direction: row;
  }
  */
}

/**
 * 1. Pass down styling to the teaser basic component
 * 2. Slider button height + some padding 
 */

.content {
  --teaser-basic-text-align: center; /* 1 */

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  width: 100%;

  /* 
  @media (width >= 1000px) {
    --teaser-basic-text-align: start; /* 1

    margin-inline-end: var(--_desktop-offset-overflow);
    padding-block: calc(var(--spacing-xx-loose) + 48px); /* 2
    width: calc(
      50% - (var(--_slide-width) / 2) - var(--_desktop-offset-overflow)
    );
  } 
  */
}

.content-inner {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

.teaser {
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  margin-inline: auto;
  max-width: 866px; /* magic number */
  /* opacity: 0; */
  /* z-index: 1; */
  /* transition: opacity 0.25s linear; */
  /* pointer-events: none; */
}

.teaser-text {
  --prose-font-size: var(--fluid-type-large);
  --prose-line-height: var(--type-large-line-height);
}

/* 1. pass styling into button-wrapper component */
.button-wrapper {
  --button-wrapper-justify-content: center; /* 1 */

  /*
  @media (width >= 1000px) {
    --button-wrapper-justify-content: start;
  }
  */
}

/**
 * This is a wrapper around the Embal carousel, useful for positioning 
 * 1. The selected Can scales, so we add some extra margin
 */
.carousel {
  display: flex;
  position: relative; /* for buttons */
  justify-content: center;
  z-index: 1;
  margin-top: calc(var(--spacing-xxxx-loose) + 48px); /* 1 */
  width: 100%;

  /* 
  @media (width >= 1000px) {
    align-items: center;
    margin-top: initial;
    width: calc(50% - (var(--_slide-width) / 2));
  }
  */
}

/* TODO: 1. Make keyboard accessible rather than none */
.slider-button-wrapper {
  display: none; /* 1 */
  position: absolute;
  top: 0;
  justify-content: space-between;
  gap: 16px;
  transform: translateY(-100%);
  z-index: 51;
  padding-inline: var(--spacing-sides);
  width: 100%;
  max-width: calc(var(--max-width-revamp-base) + (2 * var(--spacing-sides)));

  @media (width >= 1000px) {
    display: flex;
  }
}

.embla {
  position: relative;
  margin-inline: auto;
  width: var(--_carousel-width);
  overflow-x: visible;

  /*
  @media (width >= 1000px) {
    width: 100%;
  }
  */
}

/**
 * 1. needs to as long as there are visible slides in the rotator
 */
.embla__viewport {
  margin-inline: auto;
  width: var(--_carousel-width);
  /* outline: 1px solid red; */

  /*
  @media (width >= 1000px) {
    margin-inline: initial;
    width: 100%;
    min-width: calc(
      var(--_slide-width) * var(--_visible-slides-in-rotator)
    ); /* 1 
  }
  */
}

.embla__container {
  display: flex;
  visibility: hidden;
  cursor: grab;
  touch-action: pan-y pinch-zoom;
}

.embla__slide {
  flex-shrink: 0;
  width: var(--_slide-width);
  pointer-events: none;
}

.rotator {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;

  /*
  @media (width >= 1000px) {
    left: 0;
  }
  */
}

/* Most of these vars are set via the JS */
.rotator-card {
  --_desktop-rotation: var(--desktop-rotation, 0);
  --_mobile-rotation: var(--mobile-rotation, 0);
  --_selected-index: var(--selected-index, 0);
  --_index: var(--index, 0);

  position: absolute;
  left: calc((var(--_slide-width) / 2) * -1);
  transform: rotate(calc(var(--_mobile-rotation) * 4deg));
  transform-origin: 50% var(--_mobile-rotation-origin);
  z-index: 1;
  width: var(--_slide-width);

  /*
  @media (width >= 1000px) {
    left: 0;
    transform: rotate(calc(var(--_desktop-rotation) * 6deg));
    transform-origin: 50% var(--_desktop-rotation-origin);
  }
  */

  &[data-desktop-looped="true"] {
    z-index: 2;
  }
}

/* Shrink images to the left of the selected slide, `max()` stops them inverting */
/*
@media (width >= 1000px) {
  /* Selected image is big 
  .rotator-card[data-desktop-selected="true"] .image-link {
    transform: scale(
      calc((1 - var(--_desktop-rotation)) * var(--_scale-factor) + 1)
    );
  }

  .rotator-card[data-desktop-past-selected="true"] .image-link {
    transform: scale(
      max(0, calc((1 + var(--_desktop-rotation)) * (var(--_scale-factor) + 1)))
    );
  }

  /* Stop rotation on anything left of the selected slide 
  .rotator-card[data-desktop-past-selected="true"] {
    transform: rotate(0);
  }
}
*/

.image-link {
  display: block;
  transform: scale(var(--mobile-scale, 1));
  transform-origin: center bottom;

  /*
  @media (width >= 1000px) {
    transform: scale(1);
    transform-origin: center center;
  }
  */
}

.img {
  --_mobile-start-index: var(--mobile-start-index, 0);
  --_can-shadow: var(
    --scheme-can-shadow-rgb,
    var(--can-subtle-shadow-color-rgb)
  );

  /* https://linear-easing-generator.netlify.app/ */
  --spring-easing: linear(
    0,
    0.009,
    0.035 2.1%,
    0.141,
    0.281 6.7%,
    0.723 12.9%,
    0.938 16.7%,
    1.017,
    1.077,
    1.121,
    1.149 24.3%,
    1.159,
    1.163,
    1.161,
    1.154 29.9%,
    1.129 32.8%,
    1.051 39.6%,
    1.017 43.1%,
    0.991,
    0.977 51%,
    0.974 53.8%,
    0.975 57.1%,
    0.997 69.8%,
    1.003 76.9%,
    1.004 83.8%,
    1
  );

  display: block;
  filter: drop-shadow(
    var(--can-subtle-shadow-values)
      rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
  );
  transition:
    transform 2s var(--spring-easing),
    opacity 0.2s ease-out;
  transition-delay: calc(var(--_mobile-start-index) * 0.1s);

  [data-initialized="false"] & {
    transform: translateY(100%);
    opacity: 0;
  }

  [data-initialized="true"] & {
    transform: translateY(0);
    opacity: 1;
  }
}
