.container {
  /* CUSTOM STYLES START */

  /* Links */
  --prose-link-text-decoration-color: inherit;
  --prose-link-text-decoration-thickness: 0.105em;
  --prose-link-text-underline-offset: 0.125em;

  /* CUSTOM STYLES END */

  /* Colors */
  --_prose-color-body: var(--prose-color-body, inherit);
  --_prose-color-headings: var(--prose-color-headings, inherit);
  --_prose-color-counters: var(--prose-color-counters, #64748b);
  --_prose-color-bullets: var(--prose-color-bullets, #cbd5e1);

  /* Base text */
  --_prose-font-size: var(--prose-font-size, inherit);
  --_prose-line-height: var(--prose-line-height, inherit);
  --_prose-font-family: var(--prose-font-family, inherit);

  /* Links */
  --_prose-link-font-weight: var(--prose-link-font-weight, inherit);
  --_prose-link-color: var(--prose-link-color, inherit);
  --_prose-link-text-decoration-style: var(
    --prose-link-text-decoration-style,
    initial
  );
  --_prose-link-text-decoration-color: var(
    --prose-link-text-decoration-color,
    initial
  );
  --_prose-link-text-decoration-line: var(
    --prose-link-text-decoration-line,
    underline
  );
  --_prose-link-text-decoration-thickness: var(
    --prose-link-text-decoration-thickness,
    initial
  );
  --_prose-link-text-underline-offset: var(
    --prose-link-text-underline-offset,
    initial
  );

  /* Strong */
  --_prose-bold-font-weight: var(--prose-bold-font-weight, 600);
  --_prose-bold-color: var(--prose-bold-color, inherit);

  /* Lead */
  --_prose-lead-font-size: var(--prose-lead-font-size, 1.25em); /* em(20, 16) */
  --_prose-lead-line-height: var(--prose-lead-line-height, 1.6);
  --_prose-lead-margin: var(--prose-lead-margin, 1.2em);
  --_prose-lead-color: var(--prose-lead-color, initial);

  /* Paragraph */
  --_prose-p-margin: var(--prose-p-margin, 1em);

  /* Blockquote */
  --_prose-blockquote-quote-color: var(--prose-blockquote-quote-color, inherit);
  --_prose-blockquote-border-color: var(
    --prose-blockquote-border-color,
    #e2e8f0
  );
  --_prose-blockquote-font-style: var(--prose-blockquote-font-style, italic);

  /* H1 */
  --_prose-h1-font-size: var(--prose-h1-font-size, 2.25em);
  --_prose-h1-line-height: var(--prose-h1-line-height, 1.1111111111);
  --_prose-h1-margin-top: var(--prose-h1-margin-top, 0);
  --_prose-h1-margin-bottom: var(--prose-h1-margin-bottom, 0.8888888889em);
  --_prose-h1-color: var(
    --prose-h1-color,
    var(--_prose-color-headings, inherit)
  );
  --_prose-h1-font-weight: var(--prose-h1-font-weight, 800);
  --_prose-h1-bold-font-weight: var(--prose-h1-bold-font-weight, 900);

  /* H2 */
  --_prose-h2-font-size: var(--prose-h2-font-size, 1.5em); /* em(24, 16), */
  --_prose-h2-margin-top: var(--prose-h2-margin-top, 2em); /* em(48, 24), */
  --_prose-h2-margin-bottom: var(
    --prose-h2-margin-bottom,
    1em
  ); /* em(24, 24), */

  --_prose-h2-line-height: var(
    --prose-h2-line-height,
    1.3333333333
  ); /* round(32 / 24), */

  --_prose-h2-color: var(
    --prose-h2-color,
    var(--_prose-color-headings, inherit)
  );
  --_prose-h2-font-weight: var(--prose-h2-font-weight, 700);
  --_prose-h2-bold-font-weight: var(--prose-h2-bold-font-weight, 800);

  /* H3 */
  --_prose-h3-font-size: var(--prose-h3-font-size, 1.25em); /* em(20, 16), */
  --_prose-h3-margin-top: var(--prose-h3-margin-top, 1.6em); /* em(32, 20), */
  --_prose-h3-margin-bottom: var(
    --prose-h3-margin-bottom,
    0.6em
  ); /* em(12, 20), */

  --_prose-h3-line-height: var(
    --prose-h3-line-height,
    1.6
  ); /* round(32 / 20), */

  --_prose-h3-font-weight: var(--prose-h3-font-weight, 600);
  --_prose-h3-bold-font-weight: var(--prose-h3-bold-font-weight, 700);
  --_prose-h3-color: var(
    --prose-h3-color,
    var(--_prose-color-headings, inherit)
  );

  /* H4 */
  --_prose-h4-font-size: var(--prose-h4-font-size, 1em); /* em(16, 16), */
  --_prose-h4-margin-top: var(--prose-h4-margin-top, 1.5em); /* em(24, 16), */
  --_prose-h4-margin-bottom: var(
    --prose-h4-margin-bottom,
    0.5em
  ); /* em(8, 16), */

  --_prose-h4-line-height: var(
    --prose-h4-line-height,
    1.5
  ); /* round(24 / 16), */

  --_prose-h4-color: var(
    --prose-h4-color,
    var(--_prose-color-headings, inherit)
  );
  --_prose-h4-font-weight: var(--prose-h4-font-weight, 600);
  --_prose-h4-bold-font-weight: var(--prose-h4-bold-font-weight, 700);

  /* Img */
  --_prose-img-margin-top: var(--prose-img-margin-top, 2em); /* em(32, 16), */
  --_prose-img-margin-bottom: var(
    --prose-img-margin-bottom,
    2em
  ); /* em(32, 16), */

  --_prose-img-border-radius: var(--prose-img-border-radius, initial);

  /* Video */
  --_prose-video-margin-top: var(
    --prose-video-margin-top,
    2em
  ); /* em(32, 16), */

  --_prose-video-margin-bottom: var(
    --prose-video-margin-bottom,
    2em
  ); /* em(32, 16), */

  /* Picture */
  --_prose-picture-margin-top: var(
    --prose-picture-margin-top,
    2em
  ); /* em(32, 16), */

  --_prose-picture-margin-bottom: var(
    --prose-picture-margin-bottom,
    2em
  ); /* em(32, 16), */

  /* Figure */
  --_prose-figure-margin-top: var(
    --prose-figure-margin-top,
    2em
  ); /* em(32, 16), */

  --_prose-figure-margin-bottom: var(
    --prose-figure-margin-bottom,
    2em
  ); /* em(32, 16), */

  /* iFrame */
  --_prose-iframe-margin-top: var(
    --prose-iframe-margin-top,
    2em
  ); /* em(32, 16), */

  --_prose-iframe-margin-bottom: var(
    --prose-iframe-margin-bottom,
    2em
  ); /* em(32, 16), */

  /* Fig Caption */
  --_prose-figcaption-font-size: 0.875em; /* em(14, 16), */
  --_prose-figcaption-line-height: 1.4285714286; /* round(20 / 14), */
  --_prose-figcaption-margin-top: 0.8571428571em; /* em(12, 14), */
  --_prose-figcaption-color: var(--prose-figcaption-color, #64748b);

  /* Kbd */
  --_prose-kbd-color: var(--prose-kbd-color, #0f172a);
  --_prose-kbd-shadows: var(--prose-kbd-shadows, 15 23 42);

  /* Code */
  --_prose-code-font-size: var(
    --prose-code-font-size,
    0.875em
  ); /* em(14, 16), */

  --_prose-code-color: var(--prose-code-color, inherit);
  --_prose-code-font-weight: var(--prose-code-font-weight, 600);

  /* Pre */
  --_prose-pre-font-size: var(--prose-pre-font-size, 0.875em); /* em(14, 16), */
  --_prose-pre-line-height: var(
    --prose-pre-line-height,
    1.5
  ); /* round(24 / 14), */

  --_prose-pre-margin-block: var(
    --prose-pre-margin-block,
    1.7142857143em
  ); /* em(24, 14), */

  --_prose-pre-border-radius: var(
    --prose-pre-border-radius,
    0.375rem
  ); /* rem(6), */

  --_prose-pre-padding-block: var(
    --prose-pre-padding-block,
    0.8571428571em
  ); /* em(12, 14), */

  --_prose-pre-padding-inline: var(
    --prose-pre-padding-inline,
    1.1428571429em
  ); /* em(16, 14), */

  --_prose-pre-color: var(--prose-color-pre-code, #e2e8f0);
  --_prose-pre-bg-color: var(--prose-pre-bg-color, #1e293b);
  --_prose-pre-font-weight: var(--prose-pre-font-weight, 400);

  /* Lists */
  --_prose-ul-margin-top: var(--prose-ul-margin-top, 1.25em); /* em(20, 16), */
  --_prose-ul-margin-bottom: var(
    --prose-ul-margin-bottom,
    1.25em
  ); /* em(20, 16), */

  --_prose-ul-padding-inline-start: var(
    --prose-ul-padding-inline-start,
    1.625em
  ); /* em(26, 16), */

  --_prose-ol-margin-top: var(--prose-ol-margin-top, 1.25em); /* em(20, 16), */
  --_prose-ol-margin-bottom: var(
    --prose-ol-margin-bottom,
    1.25em
  ); /* em(20, 16), */

  --_prose-ol-padding-inline-start: var(
    --prose-ol-padding-inline-start,
    1.625em
  ); /* em(26, 16), */

  --_prose-li-margin-top: var(--prose-li-margin-top, 0.5em); /* em(8, 16), */
  --_prose-li-margin-bottom: var(
    --prose-li-margin-bottom,
    0.5em
  ); /* em(8, 16), */

  /* DT */
  --_prose-dt-color: var(--prose-dt-color, var(--_prose-color-headings));
  --_prose-dt-font-weight: var(--prose-dt-font-weight, 600);

  /* HR */
  --_prose-hr-margin-top: var(--prose-hr-margin-top, 3em); /* em(48, 16), */
  --_prose-hr-margin-bottom: var(
    --prose-hr-margin-bottom,
    3em
  ); /* em(48, 16), */

  --_prose-hr-color: var(--prose-hr-color, #e2e8f0);

  /* Tables */
  --_prose-table-font-size: var(
    --prose-table-font-size,
    0.875em
  ); /* em(14, 16), */

  --_prose-table-line-height: var(
    --prose-table-line-height,
    1.7142857143
  ); /* round(24 / 14), */

  --_prose-table-margin-top: var(
    --prose-table-margin-top,
    2em
  ); /* em(32, 16) */

  --_prose-table-margin-bottom: var(
    --prose-table-margin-bottom,
    2em
  ); /* em(32, 16) */

  --_prose-td-padding: var(--prose-td-padding, 0.5714285714em); /* em(8, 14), */
  --_prose-color-th-border-color: var(--prose-color-th-border-color, #cbd5e1);
  --_prose-color-td-border-color: var(--prose-color-td-border-color, #e2e8f0);

  /* CSS */
  color: var(--_prose-color-body);
  font-size: var(--_prose-font-size);
  line-height: var(--_prose-line-height);
  font-family: var(--_prose-font-family);

  &[data-appearance="light"] {
    --_prose-color-body: var(--prose-color-body, var(--color-text-light));
  }

  &[data-appearance="dark"] {
    --_prose-color-body: var(--prose-color-body, var(--color-text-dark));
  }

  & p {
    margin-top: var(--_prose-p-margin); /* em(20, 16) */
    margin-bottom: var(--_prose-p-margin); /* em(20, 16) */
  }

  & [class~="lead"] {
    margin-top: var(--_prose-lead-margin);
    margin-bottom: var(--_prose-lead-margin);
    color: var(--_prose-lead-color);
    font-size: var(--_prose-lead-font-size);
    line-height: var(--_prose-lead-line-height);
  }

  & a {
    color: var(--_prose-link-color);
    font-weight: var(--_prose-link-font-weight);
    text-decoration-line: var(--_prose-link-text-decoration-line);
    text-decoration-style: var(--_prose-link-text-decoration-style);
    text-decoration-color: var(--_prose-link-text-decoration-color);
    text-underline-offset: var(--_prose-link-text-underline-offset);
  }

  & strong {
    color: var(--_prose-bold-color);
    font-weight: var(--_prose-bold-font-weight);
  }

  & a strong {
    color: inherit;
  }

  & blockquote strong {
    color: inherit;
  }

  & h1 strong {
    color: inherit;
    font-weight: var(--_prose-h1-bold-font-weight);
  }

  & h2 strong {
    color: inherit;
    font-weight: var(--_prose-h2-bold-font-weight);
  }

  & h3 strong {
    color: inherit;
    font-weight: var(--_prose-h3-bold-font-weight);
  }

  & h4 strong {
    color: inherit;
    font-weight: var(--_prose-h4-bold-font-weight);
  }

  & thead th strong {
    color: inherit;
  }

  & img {
    display: block; /* reset */
    vertical-align: middle; /* reset */
    margin-top: var(--_prose-img-margin-top);
    margin-bottom: var(--_prose-img-margin-bottom);
    border-radius: var(--_prose-img-border-radius);
    max-width: 100%;
    height: auto;
  }

  & h1 {
    margin-top: var(--_prose-h1-margin-top);
    margin-bottom: var(--_prose-h1-margin-bottom);
    color: var(--_prose-h1-color);
    font-weight: var(--_prose-h1-font-weight);
    font-size: var(--_prose-h1-font-size);
    line-height: var(--_prose-h1-line-height);
  }

  & h2 {
    margin-top: var(--_prose-h2-margin-top);
    margin-bottom: var(--_prose-h2-margin-bottom);
    color: var(--_prose-h2-color);
    font-weight: var(--_prose-h2-font-weight);
    font-size: var(--_prose-h2-font-size);
    line-height: var(--_prose-h2-line-height);
  }

  & h3 {
    margin-top: var(--_prose-h3-margin-top);
    margin-bottom: var(--_prose-h3-margin-bottom);
    color: var(--_prose-h3-color);
    font-weight: var(--_prose-h3-font-weight);
    font-size: var(--_prose-h3-font-size);
    line-height: var(--_prose-h3-line-height);
  }

  & h4 {
    margin-top: var(--_prose-h4-margin-top);
    margin-bottom: var(--_prose-h4-margin-bottom);
    color: var(--_prose-h4-color);
    font-weight: var(--_prose-h4-font-weight);
    font-size: var(--_prose-h4-font-size);
    line-height: var(--_prose-h4-line-height);
  }

  & hr + * {
    margin-top: 0;
  }

  & h2 + * {
    margin-top: 0;
  }

  & h3 + * {
    margin-top: 0;
  }

  & h4 + * {
    margin-top: 0;
  }

  & figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & ol {
    margin-top: var(--_prose-ol-margin-top);
    margin-bottom: var(--_prose-ol-margin-bottom);
    padding-inline-start: var(--_prose-ol-padding-inline-start);
    list-style-type: decimal;

    &[type="A"] {
      list-style-type: upper-alpha;
    }

    &[type="a"] {
      list-style-type: lower-alpha;
    }

    &[type="I"] {
      list-style-type: upper-roman;
    }

    &[type="i"] {
      list-style-type: lower-roman;
    }

    &[type="1"] {
      list-style-type: decimal;
    }
  }

  & ul {
    margin-top: var(--_prose-ul-margin-top);
    margin-bottom: var(--_prose-ul-margin-bottom);
    padding-inline-start: var(--_prose-ul-padding-inline-start);
    list-style-type: disc;
  }

  & li {
    margin-top: var(--_prose-li-margin-top);
    margin-bottom: var(--_prose-li-margin-bottom);
  }

  & ol > li {
    padding-inline-start: 0.375em; /* em(6, 16), */
  }

  & ul > li {
    padding-inline-start: 0.375em; /* em(6, 16), */
  }

  & > ul > li p {
    margin-top: 0.75em; /* em(12, 16), */
    margin-bottom: 0.75em; /* em(12, 16), */
  }

  & > ul > li > *:first-child {
    margin-top: 1.25em; /* em(20, 16), */
  }

  & > ul > li > *:last-child {
    margin-bottom: 1.25em; /* em(20, 16), */
  }

  & > ol > li > *:first-child {
    margin-top: 1.25em; /* em(20, 16), */
  }

  & > ol > li > *:last-child {
    margin-bottom: 1.25em; /* em(20, 16), */
  }

  & ul ul,
  & ul ol,
  & ol ul,
  & ol ol {
    margin-top: 0.75em; /* em(12, 16), */
    margin-bottom: 0.75em; /* em(12, 16), */
  }

  & ol > li::marker {
    color: var(--_prose-color-counters);
    font-weight: 400;
  }

  & ul > li::marker {
    color: var(--_prose-color-bullets);
  }

  & dl {
    margin-top: 1.25em; /* em(20, 16) */
    margin-bottom: 1.25em; /* em(20, 16) */
  }

  & dt {
    margin-top: 1.25em; /* em(20, 16) */
    color: var(--_prose-dt-color);
    font-weight: var(--_prose-dt-font-weight);
  }

  & dd {
    margin-top: 0.5em; /* em(8, 16) */
    padding-inline-start: 1.625em; /* em(26, 16) */
  }

  & hr {
    margin-top: var(--_prose-hr-margin-top);
    margin-bottom: var(--_prose-hr-margin-bottom);
    border-width: 0; /* reset */
    border-top-width: 1px;
    border-style: solid; /* reset */
    border-color: var(--_prose-hr-color);
  }

  & blockquote {
    margin-top: 1.6em; /* em(32, 20) */
    margin-bottom: 1.6em; /* em(32, 20) */
    margin-inline: 0; /* override */
    border-inline-start-width: 0.25rem;
    border-inline-start-style: solid;
    border-inline-start-color: var(--_prose-blockquote-border-color);
    padding-inline-start: 1em; /* em(20, 20) */
    quotes: "\201C" "\201D" "\2018" "\2019";
    color: var(--_prose-blockquote-quote-color);
    font-style: var(--_prose-blockquote-font-style);
    font-weight: 500;

    & p:first-of-type::before {
      content: open-quote;
    }

    & p:last-of-type::after {
      content: close-quote;
    }
  }

  & picture {
    display: block;
    margin-top: var(--_prose-picture-margin-top);
    margin-bottom: var(--_prose-picture-margin-top);

    & > img {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & video {
    margin-top: var(--_prose-video-margin-top);
    margin-bottom: var(--_prose-video-margin-bottom);
  }

  & kbd {
    box-shadow:
      0 0 0 1px rgb(var(--_prose-kbd-shadows) / 10%),
      0 3px 0 rgb(var(--_prose-kbd-shadows) / 10%);
    border-radius: 0.3125rem; /* 5/16 */
    padding-inline: 0.375em; /* em(6, 16) */
    padding-block: 0.1875em; /* em(3, 16) */
    color: var(--_prose-kbd-color);
    font-weight: 500;
    font-size: 0.875em; /* em(14, 16), */
    font-family: inherit;
  }

  & code {
    color: var(--_prose-code-color);
    font-weight: var(--_prose-code-font-weight);
    font-size: var(--_prose-code-font-size);

    &::before {
      content: "`";
    }

    &::after {
      content: "`";
    }
  }

  & a code {
    color: inherit;
  }

  & h2 code {
    font-size: 0.875em; /* em(21, 24), */
  }

  & h3 code {
    font-size: 0.9em; /* em(18, 20), */
  }

  & pre code {
    border-width: 0;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;

    &::before {
      content: none;
    }

    &::after {
      content: none;
    }
  }

  & :is(h1, h2, h3, h4, h5, h6, blockquote, thead th) code {
    color: inherit;
  }

  & pre {
    margin-block: var(--_prose-pre-margin-block);
    border-radius: var(--_prose-pre-border-radius);
    background-color: var(--_prose-pre-bg-color);
    padding-inline: var(--_prose-pre-padding-inline);
    padding-block: var(--_prose-pre-padding-block);
    overflow-x: auto;
    color: var(--_prose-pre-color);
    font-weight: var(--_prose-pre-font-weight);
    font-size: var(--_prose-pre-font-size);
    line-height: var(--_prose-pre-line-height);

    & :is(code::before, code::after) {
      content: none;
    }
  }

  & table {
    margin-top: var(--_prose-table-margin-top);
    margin-bottom: var(--_prose-table-margin-bottom);
    border-color: inherit; /* reset */
    border-collapse: collapse; /* reset */
    width: 100%;
    table-layout: auto;
    font-size: var(--_prose-table-font-size);
    line-height: var(--_prose-table-line-height);
    text-align: start;
    text-indent: 0; /* reset */
  }

  & thead {
    border-width: 0; /* reset */
    border-bottom-width: 1px;
    border-bottom-style: solid; /* reset */
    border-bottom-color: var(--_prose-color-th-border-color);

    & th {
      vertical-align: bottom;
      padding-inline-end: var(--_prose-td-padding);
      padding-bottom: var(--_prose-td-padding);
      padding-inline-start: var(--_prose-td-padding);
      color: var(--_prose-color-headings);
      font-weight: 600;
      text-align: inherit; /* bug, needs to inherit table text algin */

      &:first-child {
        padding-inline-start: 0;
      }

      &:last-child {
        padding-inline-end: 0;
      }
    }
  }

  & tbody tr {
    border-width: 0; /* reset */
    border-bottom-width: 1px;
    border-style: solid; /* reset */
    border-bottom-color: var(--_prose-color-td-border-color);

    &:last-child {
      border-bottom-width: 0;
    }
  }

  & tbody td {
    vertical-align: baseline;
  }

  & tfoot {
    border-top-width: 1px;
    border-top-color: var(--_prose-color-th-border-color);

    & td {
      vertical-align: top;
    }
  }

  & tbody td,
  & tfoot td {
    padding-top: var(--_prose-td-padding);
    padding-bottom: var(--_prose-td-padding);
    padding-inline-start: var(--_prose-td-padding);
    padding-inline-end: var(--_prose-td-padding);
  }

  & tbody td:first-child,
  & tfoot td:first-child {
    padding-inline-start: 0;
  }

  & tbody td:last-child,
  & tfoot td:last-child {
    padding-inline-end: 0;
  }

  & figure {
    margin-top: var(--_prose-figure-margin-top);
    margin-inline: 0; /* override */
    margin-bottom: var(--_prose-figure-margin-bottom);
    color: var(--_prose-figcaption-color);
  }

  & figcaption {
    margin-top: var(--_prose-figcaption-margin-top);
    color: var(--_prose-figcaption-color);
    font-size: var(--_prose-figcaption-font-size);
    line-height: var(--_prose-figcaption-line-height);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  & iframe {
    display: block; /* reset */
    vertical-align: middle; /* reset */
    margin-top: var(--_prose-iframe-margin-top);
    margin-bottom: var(--_prose-iframe-margin-bottom);
    max-width: 100%;
    height: auto;
  }
}

/* target youtube (+ youtube-nocookie) videos */
.container iframe[src^="https://www.youtube"]
{
  aspect-ratio: 16 / 9;
  width: 100%;
}
