.container {
  position: fixed;
  bottom: 0;
  z-index: var(--z-index-disclaimer);
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--color-surface-glass-dark-10);
  background-color: var(--color-surface-solid-light-lighter);
  padding: 30px 0;
  padding-inline: var(--spacing-sides);
  width: 100%;
  color: var(--color-text);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.content {
  display: grid;
  align-items: center;
  grid-column-gap: 12px;
  position: relative;
  grid-template-columns: repeat(6, 1fr);

  @media (width >= 650px) {
    grid-column-gap: 20px;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;
  }

  @media (width >= 1000px) {
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
}

.image-column {
  display: block;
}

.image {
  display: block;
  margin: 0 auto;
  width: 38px;

  @media (width >= 650px) {
    width: 48px;
  }
}

.text-column {
  grid-column-start: 2;
  grid-column-end: 6;
  font-size: var(--font-size-11px);
  line-height: calc(16 / 11);

  @media (width >= 650px) {
    grid-column-start: 2;
    grid-column-end: 8;
    font-size: var(--font-size-16px);
    line-height: calc(22 / 16);
  }

  @media (width >= 1000px) {
    grid-column-end: 9;
  }
}

.title {
  margin-bottom: 5px;
  color: var(--color-text-heading);
  font-weight: 700;

  @media (width >= 650px) {
    margin-bottom: 8px;
  }
}

.text {
  color: var(--color-text-subtle);
}

.button {
  position: absolute;
  top: 0;
  right: var(--spacing-sides);
  -webkit-appearance: none;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M24 0v24H0V0' data-name='Bounding area'%3E%3C/path%3E%3Cpath fill='%23DB0A40' d='M19.18 5.18l-.35-.36a.51.51 0 0 0-.71 0L12 10.94 5.89 4.82a.51.51 0 0 0-.71 0l-.35.36a.48.48 0 0 0 0 .7L11 12l-6.17 6.12a.48.48 0 0 0 0 .7l.35.36a.51.51 0 0 0 .71 0L12 13.06l6.11 6.12a.51.51 0 0 0 .71 0l.35-.36a.48.48 0 0 0 0-.7L13.07 12l6.11-6.12a.48.48 0 0 0 0-.7z'%3E%3C/path%3E%3C/svg%3E");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 0;
  width: 24px;
  height: 24px;
}
